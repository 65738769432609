<template>
  <div>    
    <div class="vx-row mt-8">      
      <div class="vx-col xl:w-2/3 col-cus-12 w-full my-8" v-if="!isReseller">
        <vx-card>
            <div class="card-title">
                <h2 class="w-auto py-2">Billing Summary</h2>
                <vs-button v-if="!isShowBillingV2" @click="additionalUsersActive = true, generateDetionUsersOptions()" :title="LabelConstant.buttonLabelPurchaseAdditionalUsers" class="bg-actionbutton" type="filled">{{ LabelConstant.buttonLabelPurchaseAdditionalUsers }}</vs-button>    
            </div>
            <vs-table class="table-list" ref="table" :data="[1,2]" v-if="billingSummary && !isShowBillingV2">
                <template slot="thead">                                     
                    <vs-th sort-key="service">Service</vs-th>
                    <vs-th sort-key="notes">Notes</vs-th>
                    <vs-th sort-key="qty">Qty</vs-th>
                    <vs-th sort-key="price">Price</vs-th>
                    <vs-th sort-key="subtotal">Sub Total</vs-th>
                </template>
                <template slot-scope="{data}">
                    <tbody>
                        <vs-tr>                       
                            <vs-td>
                                <p>Base Package - Visual Visitor</p>
                            </vs-td>   
                            <vs-td>
                                <p>Includes 3 user accounts, price does not include page view overage.</p>
                            </vs-td>   
                            <vs-td>
                                <p>1</p>
                            </vs-td>  
                            <vs-td>
                                <p>{{billingSummary.Base_Price}}</p>
                            </vs-td>
                            <vs-td>
                                <p class="text-secondary">{{billingSummary.Base_Price}}</p>
                            </vs-td>                                 
                        </vs-tr>
                        <vs-tr>
                            <vs-td>
                                <p>Additional User Accounts</p>
                            </vs-td>   
                            <vs-td>
                                <p>Above the 3 accounts included.</p>
                            </vs-td>   
                            <vs-td>
                                <p>{{billingSummary.Billing_Users_Additional}}</p>
                            </vs-td>  
                            <vs-td>
                                <p>$10.00</p>
                            </vs-td>
                            <vs-td>
                                <p class="text-secondary">{{billingSummary.Extra_User_Price}}</p>
                            </vs-td>                                 
                        </vs-tr>
                        <vs-tr>
                            <vs-td>
                                <p>Local Call Tracking Numbers</p>
                            </vs-td>   
                            <vs-td>
                                <p>Above the 5 local numbers included, price does not include usage.</p>
                            </vs-td>   
                            <vs-td>
                                <p>{{billingSummary.Extra_Call_Tracking_Local_Number}}</p>
                            </vs-td>  
                            <vs-td>
                                <p>$2.00</p>
                            </vs-td>
                            <vs-td>
                                <p class="text-secondary">{{billingSummary.Extra_Call_Tracking_Local_Number_Price}}</p>
                            </vs-td>                                 
                        </vs-tr>
                        <vs-tr>
                            <vs-td>
                                <p>Toll Free Call Tracking Numbers</p>
                            </vs-td>   
                            <vs-td>
                                <p>Price does not include usage.</p>
                            </vs-td>   
                            <vs-td>
                                <p>{{billingSummary.Extra_Call_Tracking_Toll_Free_Number}}</p>
                            </vs-td>  
                            <vs-td>
                                <p>$2.00</p>
                            </vs-td>
                            <vs-td>
                                <p class="text-secondary">{{billingSummary.Extra_Call_Tracking_Toll_Free_Number_Price}}</p>
                            </vs-td>                                 
                        </vs-tr>
                    </tbody>
                    <tfoot>
                        <vs-tr>
                            <vs-td></vs-td>
                            <vs-td></vs-td>
                            <vs-td></vs-td>
                            <vs-td>
                                <p class="total-font font-medium">Total</p>
                            </vs-td>
                            <vs-td>
                                <span class="total-font text-secondary">{{billingSummary.Total_Billing_Amount}}</span>
                            </vs-td>
                        </vs-tr>
                    </tfoot>
                </template>
            </vs-table>
            <!-- currunt plan section -->
            <div class="vx-row mt-8" v-if="isShowBillingV2">
                <div class="vx-col xl:w-1/3 col-cus-12 w-full my-8">
                    <div class="plan-card flex h-full">
                        <p>Current Plan</p>
                        <h5 class="text-primary">{{currentBillingPlan}} </h5>
                        <router-link
                            class="mr-1 float-left my-2 btn-link"
                            :to="{ name: 'Invoice' }"
                            >Show my invoices</router-link
                        >
                    </div>
                </div>      
                <div class="vx-col xl:w-2/3 col-cus-12 w-full my-8" >
                    <div class="common-table  p-4">
                        <!-- form 1 -->
                        <form  class="px-3" data-vv-scope="selectPlanForm"> 
                            <div class="vx-row">
                                <div class="vx-col  w-full">
                                    <h4 class="pr-4 mb-4 w-100">I would like to add....</h4>
                                    <vs-radio v-for="(option, index) in listMoreInfoOptions" :key="index" class="mr-2 mb-2 flex" :vs-value="option.Key" v-model="selectedOption" v-validate="'required'" name="selectedOption">
                                        {{option.Value}}
                                    </vs-radio>
                                    <span class="text-danger text-sm error-msg">{{errors.first("selectPlanForm.selectedOption")}}</span>
                                </div>
                                <div class="vx-col my-4 w-full">
                                    <vs-button
                                        title="Next"
                                        color="primary"
                                        type="filled"
                                        class="float-left"
                                        @click="requestPlanAdditionalInfo()"
                                        >Next
                                    </vs-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!--end currunt plan section -->
        </vx-card>
      </div>
      <div class="vx-col xl:w-1/3 col-cus-12 w-full my-8">
        <vx-card class="credit-card">
          <div class="card-title">
            <h2 class="w-auto py-2">Credit Card Information</h2>
            <vs-button @click="updateCardActive = true"  :title="LabelConstant.buttonLabelUpdateCreditCard" class="bg-actionbutton" type="filled">{{ LabelConstant.buttonLabelUpdateCreditCard }}</vs-button>
          </div> 
          <div class="credit-card-design bg-primary-gradient" v-if="billingSummary">
            <span class="pay-img">
                <img v-show="billingSummary.Billing_Card_Type == 'American Express'" src="../../assets/images/american-express.png">
                <img v-show="billingSummary.Billing_Card_Type == 'MasterCard'" src="../../assets/images/mastercard.png">
                <img v-show="billingSummary.Billing_Card_Type == 'Visa'" src="../../assets/images/visa.png">
            </span>
            <span class="card-no">{{billingSummary.Billing_Card_Number}}</span>
            <div class="name-date">
                <h5 class="float-left"><span>Card Name</span>{{billingSummary.Billing_Name_On_Card}}</h5>
                <h5 class="float-right"><span>Card Expiration</span>{{billingSummary.Billing_Card_Exp}}</h5>
            </div>
          </div>
        </vx-card>
      </div>
    </div>

    <!-- Purchase or Cancel Additional Users Popup --> 
    <vs-popup class="transaction-popup" :active.sync="addDeleteUserConfirmActive">             
        <div class="transaction-details">
            <div class="popup-icon">
                <img v-show="purchaseUserActionType == 'add'" src="../../assets/images/warning.svg">
                <img v-show="purchaseUserActionType == 'delete'" src="../../assets/images/trash.svg">
            </div>
            <h2 v-show="purchaseUserActionType == 'add'">Purchase Additional Users?</h2>
            <h2 v-show="purchaseUserActionType == 'delete'">Cancel User Accounts?</h2>
            <p v-show="purchaseUserActionType == 'add'">{{additionalUsers}}</p>
            <p v-show="purchaseUserActionType == 'delete'">{{deletionUsers}}</p>
            <div class="float-left mt-6 w-full text-center">             
                <vs-button @click="addPurchasedUsers()" v-show="purchaseUserActionType == 'add'" color="success" class="mt-0 sm:w-auto w-full" type="filled">{{ LabelConstant.buttonLabelPurchaseUserAccounts }}</vs-button>
                <vs-button @click="deletePurchasedUsers()" v-show="purchaseUserActionType == 'delete'" color="danger"  class="mt-0 sm:w-auto w-full"  type="filled">{{ LabelConstant.buttonLabelCancelUserAccounts }}</vs-button>
                <vs-button @click="addDeleteUserConfirmActive=false" color="grey" type="border" class="grey-btn sm:ml-4  ml-0 mt-2 sm:mt-0 sm:w-auto w-full">{{ LabelConstant.buttonLabelCancel }}</vs-button>            
            </div>
        </div>            
    </vs-popup>

    <!-- Purchase Additional Users Popup -->      
    <vs-popup class="popup-width" title="Purchase or Cancel Additional Users" :active.sync="additionalUsersActive"> 
        <template>
            <div class="vx-row mb-3" v-if="billingSummary">
                <div class="vx-col w-full">
                    <div class="card-title">
                        <h2> User Account Summary</h2>                        
                    </div>
                    <ul class="account-summary">
                        <li class="pt-0">
                            <h4>Base User Accounts</h4>
                            <span>{{billingSummary.User_Accounts_Base}}</span>
                        </li>
                        <li class="sm:pt-0 pt-2">
                            <h4>Additional User Accounts</h4>
                            <span>{{billingSummary.Billing_Users_Additional}}</span>
                        </li>
                        <li>
                            <h4>Total User Accounts</h4>
                            <span>{{billingSummary.User_Total_Accounts_Available}}</span>
                        </li>
                        <li>
                            <h4>Total User Accounts</h4>
                            <span>{{billingSummary.User_Total_Accounts_Available}}</span>
                        </li>
                        <li>
                            <h4>Provisioned User Accounts</h4>
                            <span>{{billingSummary.User_Total_Provisioned_Accounts}}</span>
                        </li>
                        <li>
                            <h4>Available User Accounts</h4>
                            <span>{{billingSummary.User_Remaining_Accounts_To_Provision}}</span>
                        </li>
                    </ul>
                </div>
                <div class="vx-col w-full option-section">
                    <div class="card-title">
                        <h2> Options </h2>                        
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full mb-8">
                            <div class="option-item">
                                <vs-radio v-model="purchaseUserActionType" vs-value="add" :color="addUserRadioColor" @change="changeColor()"> <span :class="addUserTextColor">I would like to purchase additional user accounts</span></vs-radio>
                                <ul>
                                    <li>
                                        <span>Purchase Additional Users</span>
                                        <v-select v-model="additionalUsers" :options="additionalUsersOptions" :clearable="false"/>
                                        <span>$10/month</span>
                                    </li>                                
                                </ul>
                            </div>
                        </div>
                        <div class="vx-col w-full mb-8" v-show="this.billingSummary.User_Accounts_Available_To_Cancel > 0">
                            <div class="option-item">
                                <vs-radio v-model="purchaseUserActionType" vs-value="delete" :color="deleteUserRadioColor" @change="changeColor()"><span :class="deletionUserTextColor">I would like to cancel additional user accounts</span></vs-radio>
                                <ul>
                                    <li>
                                        <span>Cancel Additional Users</span>
                                        <v-select v-model="deletionUsers" :options="deletionUsersOptions" :clearable="false"/>
                                        <span>$-10/month</span>
                                    </li>                                
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="float-right mb-8">
            <vs-button @click="addDeleteUserConfirmActive=true" v-show="purchaseUserActionType == 'add'" type="filled" color="primary">{{ LabelConstant.buttonLabelPurchaseAdditionalUsers }}</vs-button>
             <vs-button @click="addDeleteUserConfirmActive=true" v-show="purchaseUserActionType == 'delete'" color="danger" type="filled">{{ LabelConstant.buttonLabelRemoveAdditionalUsers }}</vs-button>
            <vs-button @click="additionalUsersActive = false" color="grey" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>            
    </vs-popup>
    
    <!-- Update Credit Card -->
    <vs-popup class="add-new" title="Update Credit Card" :active.sync="updateCardActive">
        <template>
            <form data-vv-scope="updateCardForm">
                <div class="vx-row">
                    <div class="vx-col md:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Name on Card"  v-model="billingNameOnCard" name="billingNameOnCard" v-validate="'required'"/>
                        <span class="text-danger text-sm">{{ errors.first('updateCardForm.billingNameOnCard') }}</span>
                    </div>
                    <div class="vx-col md:w-1/2 w-full mb-6">
                        <span class="vs-input--label">Card Type</span>                    
                        <v-select v-model="billingCardType" :options="billingCardTypeOptions" :clearable="false"/>
                    </div>
                </div> 
                <div class="vx-row">
                    <div class="vx-col md:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Credit Card Number" v-model="billingCardNumber" name="billingCardNumber" v-validate="'required|credit_card'"/>
                        <span class="text-danger text-sm" v-show="billingCardNumberError">{{ billingCardNumberError }}</span>
                        <span class="text-danger text-sm">{{ errors.first('updateCardForm.billingCardNumber') }}</span>
                        <p>Please use numbers only, no spaces or dashes.</p>
                    </div>
                    <div class="vx-col md:w-1/2 w-full mb-6">
                        <span class="block"><label class="vs-input--label ">Credit Card Expiration</label></span>
                        <v-select class="w-1/3 float-left mr-4" v-model="billingCardExpMonth" :options="billingCardExpMonthOptions" :clearable="false"/>
                        <v-select class="w-1/3 float-left" v-model="billingCardExpYear" :options="billingCardExpYearOptions" :clearable="false"/>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Billing Address 1" v-model="billingAddress1" name="billingAddress1" v-validate="'required'"/>
                        <span class="text-danger text-sm">{{ errors.first('updateCardForm.billingAddress1') }}</span>
                    </div>
                    <div class="vx-col md:w-1/2 w-full mb-6">                                    
                        <vs-input class="w-full" label="Billing Address 2" v-model="billingAddress2" />
                    </div>
                </div> 
                <div class="vx-row">                
                    <div class="vx-col md:w-1/3 w-full mb-6">                                     
                        <vs-input class="w-full" label="City" v-model="billingCity" name="billingCity" v-validate="'required'"/>
                        <span class="text-danger text-sm">{{ errors.first('updateCardForm.billingCity') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mb-6">                                     
                        <vs-input class="w-full" label="State" v-model="billingState" name="billingState" v-validate="'required'"/>
                        <span class="text-danger text-sm">{{ errors.first('updateCardForm.billingState') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mb-6">                                     
                        <vs-input class="w-full" label="Zip" v-model="billingZip" name="billingZip" v-validate="'required'"/>
                        <span class="text-danger text-sm">{{ errors.first('updateCardForm.billingZip') }}</span>
                    </div>
                </div> 
                <div class="vx-row">                
                    <div class="vx-col w-full mb-6">
                        <vs-input class="w-full" label="Billing Email" v-model="billingEmail" name="billingEmail" v-validate="'required|email'"/>
                        <span class="text-danger text-sm">{{ errors.first('updateCardForm.billingEmail') }}</span>
                    </div>
                </div>
            </form>
        </template>            
        <div class="flex justify-end modal-action-buttons w-full float-left mt-6 mb-8">                        
            <vs-button @click="updateCreditCard()" type="filled" color="primary">{{ LabelConstant.buttonLabelUpdateCreditCard }}</vs-button>
            <vs-button @click="cancelUpdateCreditCard()" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Thank You -->
    <vs-popup
      class="tag-info close-open"
      title="Thank You"
      :active.sync="thankYouActive"
      >
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col input-text w-full">
            <div class="popup-icon">
              <img src="../../../src/assets/images/thankyou.svg" />
            </div>
            <p>
              Thank You for your interest, a representative will contact you shortly to assist with the request.
            </p>
          </div>
        </div>
      </template>
      <div class="float-right mt-6">
        <vs-button
          @click="closeThankYou()"
          color="primary"
          type="filled"
          class="small-btn mb-6"
          >{{ LabelConstant.buttonLabelClose }}</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import vSelect from 'vue-select'
import { Validator } from 'vee-validate';

const dict = {
    custom: {
        billingNameOnCard: {
            required: 'A Name is required'
        },
        billingCardNumber: {
            required: 'A Credit Card Number is required',
            credit_card: 'Please enter valid Credit Card Number.'
        },
        billingAddress1: {
            required: 'Billing address must be more than 1 and less than 300 characters'
        },
        billingCity: {
            required: 'A City is required'
        },
        billingState: {
            required: 'A State is required'
        },
        billingZip: {
            required: 'A Zip is required'
        },
        billingEmail: {
            required: 'An Email is required',
            email: 'Please enter a valid email address'
        },
        selectedOption: {
            required: 'Please select the option.'
        }
    }
}
Validator.localize('en', dict);

export default {
    components: {
        VxCard,
        vSelect 
    },
    data() {
        return {
            isShowBillingV2: false,

            isReseller:  null,

            billingSummary:null,

            updateCardActive:false,
            billingNameOnCard: null,
            billingCardType: 'Visa',
            billingCardNumber: null,
            billingCardExpMonth: '1',
            billingCardExpYear: new Date().getFullYear(),
            billingAddress1: null,
            billingAddress2: null,
            billingCity: null,
            billingState: null,
            billingZip: null,
            billingEmail: null,

            billingCardTypeOptions:['Visa','MasterCard','American Express'],
            billingCardExpMonthOptions:['1','2','3','4','5','6','7','8','9','10','11','12'],
            billingCardExpYearOptions:[],
            billingCardNumberError: null,

            additionalUsersActive:false,

            purchaseUserActionType: 'add',
            addUserRadioColor: 'success',
            addUserTextColor: 'text-success',
            deleteUserRadioColor: 'secondary',
            deletionUserTextColor: '',

            additionalUsers:'1',
            additionalUsersOptions:[],

            deletionUsers: '1',
            deletionUsersOptions:[],

            addDeleteUserConfirmActive: false,

            listMoreInfoOptions: [],
            selectedOption: null,
            currentBillingPlan: null,
            thankYouActive: false
        }
    }, 
    mounted(){
        let uid = this.$route.params.id
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        this.isReseller = token[uid].isReseller
        this.isShowBillingV2 = token[uid].showBillingV2
        if(this.$route.query.userpurchase){
            this.additionalUsersActive = true;
        }
    },
    created(){
        this.getBillingInfo()
        this.generateAdditionalUserOptions()
        this.getCurrentBillingPlan()
        this.listRequestMoreInfoOptions()
        this.getYears()
    },
    methods: {
        getCurrentBillingPlan(){
            this.$vs.loading()
            this.axios.get("/ws/BillingInfo/GetCurrentBillingPlan").then(response => {
                let data = response.data;
                this.currentBillingPlan = data
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
            });    
        },
        listRequestMoreInfoOptions(){
            this.$vs.loading()
            this.axios.get("/ws/BillingInfo/ListRequestMoreInfoOptions").then(response => {
                let data = response.data;
                this.listMoreInfoOptions = data
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
            });
        },
        requestPlanAdditionalInfo(){
            this.$validator.validateAll('selectPlanForm').then(result => {
                if (result) {
                    let requestParmas= {
                        InfoRequestType: this.selectedOption
                    }
                    this.$vs.loading()
                    this.axios.post("/ws/BillingInfo/RequestPlanAdditionalInfo", requestParmas).then(() => {
                        this.thankYouActive = true
                        this.selectedOption = null
                        this.$vs.notify({
                            title:'Success',
                            text: 'Additional plan request sent successfully.',
                            color: "success",
                            iconPack: 'feather',
                            icon:'icon-check',
                            position: "top-right",
                            time:4000
                        });
                        this.$vs.loading.close()
                    }).catch(e => {
                        this.showError(e);
                        this.$vs.loading.close()
                        this.$vs.notify({
                            title:'Error',
                            text: e.response.data.Errors[0].Message,
                            color: "danger",
                            iconPack: 'feather',
                            icon:'icon-alert-circle',
                            position:"top-right",
                            time:4000
                        });
                    });
                }
            })
        },
        closeThankYou(){
            this.thankYouActive = false;
            setTimeout(() => {
                this.errors.clear("selectPlanForm");
            }, 20);
        },
        changeColor(){
            if(this.purchaseUserActionType == 'add'){
                this.addUserRadioColor = 'success'
                this.addUserTextColor = 'text-success'
                this.deleteUserRadioColor = 'secondary'
                this.deletionUserTextColor = ''
            }else{
                this.addUserRadioColor = 'secondary'
                this.addUserTextColor = ''
                this.deleteUserRadioColor = 'danger'
                this.deletionUserTextColor = 'text-danger'
            }
        },
        generateDetionUsersOptions(){
            this.deletionUsersOptions = []
            for (let i = 1; i <= this.billingSummary.User_Accounts_Available_To_Cancel; i++) {
                this.deletionUsersOptions.push(i.toString())
            }
        },
        generateAdditionalUserOptions(){
            this.additionalUsersOptions = []
            for (let i = 1; i <= 40; i++) {
                this.additionalUsersOptions.push(i.toString())
            }
        },
        getBillingInfo(){
            this.$vs.loading()
            this.axios.get("/ws/BillingInfo/GetBillingInfo").then(response => {
                let data = response.data;
                this.billingSummary = data
                this.$vs.loading.close()
            }).catch(e => {
                this.$vs.loading.close()
                this.showError(e);
            });
        },
        cancelUpdateCreditCard(){
            this.updateCardActive = false
            this.billingCardType = 'Visa'
            this.billingCardExpMonth = '1'
            this.billingCardExpYear = new Date().getFullYear()
            this.billingCardNumber = null
            this.billingNameOnCard = null
            this.billingAddress1 = null
            this.billingAddress2 = null
            this.billingCity = null
            this.billingState = null
            this.billingZip = null
            this.billingEmail = null
            setTimeout(() => {
                this.errors.clear("updateCardForm");
            }, 20);
        },
        updateCreditCard(){
            this.$validator.validateAll('updateCardForm').then(result => {
                if (result) {
                    let updateCreditCardParams = {
                        Billing_Card_Type: this.billingCardType,
                        Billing_Card_Exp_Month: this.billingCardExpMonth,
                        Billing_Card_Exp_Year: this.billingCardExpYear,
                        Billing_Card_Number: this.billingCardNumber,
                        Billing_Name_On_Card: this.billingNameOnCard,
                        Billing_Address1: this.billingAddress1,
                        Billing_Address2: this.billingAddress2,
                        Billing_City: this.billingCity,
                        Billing_State: this.billingState,
                        Billing_Zip: this.billingZip,
                        Billing_Email: this.billingEmail
                    }
                    this.$vs.loading()
                    this.axios.post("/ws/BillingInfo/UpdateCreditCard", updateCreditCardParams).then(() => {
                        this.billingCardNumberError = null
                        this.getBillingInfo()
                        this.cancelUpdateCreditCard()
                        this.$vs.notify({
                            title:'Success',
                            text: 'Your credit card information has been updated successfully.',
                            color: "success",
                            iconPack: 'feather',
                            icon:'icon-check',
                            position: "top-right",
                            time:4000
                        });
                        this.$vs.loading.close()
                    }).catch(e => {
                        this.showError(e);
                        this.$vs.loading.close()
                        if(e.response.data.Errors[0].Key == 'Billing_Card_Number'){
                            this.billingCardNumberError = e.response.data.Errors[0].Message
                        }
                        this.$vs.notify({
                            title:'Error',
                            text: e.response.data.Errors[0].Message,
                            color: "danger",
                            iconPack: 'feather',
                            icon:'icon-alert-circle',
                            position:"top-right",
                            time:4000
                        });
                    });
                }
            })
        },
        cancelAddPurchasedUsers(){
            this.additionalUsersActive = false
            this.deletionUsersOptions = []
            this.deletionUsers = '1'
            this.additionalUsers = '1'
            this.purchaseUserActionType = 'add'
            this.addUserRadioColor = 'success'
            this.addUserTextColor = 'text-success'
            this.deleteUserRadioColor = 'secondary'
            this.deletionUserTextColor = ''
        },
        addPurchasedUsers(){
            let addPurchasedUsersParams = {
                Customer_Id: "",
                Base_Price: this.billingSummary.Base_Price,
                Billing_Users_Additional: this.billingSummary.Billing_Users_Additional,
                Extra_User_Price: this.billingSummary.Extra_User_Price,
                Total_Billing_Amount: this.billingSummary.Total_Billing_Amount,
                Date_Paying_Customer: this.billingSummary.Date_Paying_Customer,
                Billing_Name_On_Card: this.billingSummary.Billing_Name_On_Card,
                Billing_Card_Type: this.billingSummary.Billing_Card_Type,
                Billing_Card_Number: this.billingSummary.Billing_Card_Number,
                Billing_Card_Exp: this.billingSummary.Billing_Card_Exp,
                User_Accounts_Base: this.billingSummary.User_Accounts_Base,
                User_Total_Accounts_Available: this.billingSummary.User_Total_Accounts_Available,
                User_Total_Provisioned_Accounts: this.billingSummary.User_Total_Provisioned_Accounts,
                User_Remaining_Accounts_To_Provision: this.billingSummary.User_Remaining_Accounts_To_Provision,
                User_Accounts_Available_To_Cancel: this.billingSummary.User_Accounts_Available_To_Cancel,
                Users_To_Delete: this.deletionUsers,
                Users_To_Add: this.additionalUsers,
                Extra_Call_Tracking_Local_Number: this.billingSummary.Extra_Call_Tracking_Local_Number,
                Extra_Call_Tracking_Toll_Free_Number: this.billingSummary.Extra_Call_Tracking_Toll_Free_Number,
                Extra_Call_Tracking_Local_Number_Price: this.billingSummary.Extra_Call_Tracking_Local_Number_Price,
                Extra_Call_Tracking_Toll_Free_Number_Price: this.billingSummary.Extra_Call_Tracking_Toll_Free_Number_Price,
                action: this.purchaseUserActionType
            }
            this.$vs.loading()
            this.axios.post("/ws/BillingInfo/AddPurchasedUsers", addPurchasedUsersParams).then(() => {
                this.getBillingInfo()
                this.cancelAddPurchasedUsers()
                this.addDeleteUserConfirmActive = false
                this.$vs.notify({
                    title:'Success',
                    text: 'Your additional users have been updated successfully.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
                this.$vs.loading.close()
            }).catch(e => {
                this.showError(e);
                this.$vs.notify({
                    title:'Error',
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: 'feather',
                    icon:'icon-alert-circle',
                    position:"top-right",
                    time:4000
                });
            });    
        },
        deletePurchasedUsers(){
            let addPurchasedUsersParams = {
                Customer_Id: "",
                Base_Price: this.billingSummary.Base_Price,
                Billing_Users_Additional: this.billingSummary.Billing_Users_Additional,
                Extra_User_Price: this.billingSummary.Extra_User_Price,
                Total_Billing_Amount: this.billingSummary.Total_Billing_Amount,
                Date_Paying_Customer: this.billingSummary.Date_Paying_Customer,
                Billing_Name_On_Card: this.billingSummary.Billing_Name_On_Card,
                Billing_Card_Type: this.billingSummary.Billing_Card_Type,
                Billing_Card_Number: this.billingSummary.Billing_Card_Number,
                Billing_Card_Exp: this.billingSummary.Billing_Card_Exp,
                User_Accounts_Base: this.billingSummary.User_Accounts_Base,
                User_Total_Accounts_Available: this.billingSummary.User_Total_Accounts_Available,
                User_Total_Provisioned_Accounts: this.billingSummary.User_Total_Provisioned_Accounts,
                User_Remaining_Accounts_To_Provision: this.billingSummary.User_Remaining_Accounts_To_Provision,
                User_Accounts_Available_To_Cancel: this.billingSummary.User_Accounts_Available_To_Cancel,
                Users_To_Delete: this.deletionUsers,
                Users_To_Add: this.additionalUsers,
                Extra_Call_Tracking_Local_Number: this.billingSummary.Extra_Call_Tracking_Local_Number,
                Extra_Call_Tracking_Toll_Free_Number: this.billingSummary.Extra_Call_Tracking_Toll_Free_Number,
                Extra_Call_Tracking_Local_Number_Price: this.billingSummary.Extra_Call_Tracking_Local_Number_Price,
                Extra_Call_Tracking_Toll_Free_Number_Price: this.billingSummary.Extra_Call_Tracking_Toll_Free_Number_Price,
                action: this.purchaseUserActionType
            }
            this.$vs.loading()
            this.axios.post("/ws/BillingInfo/DeletePurchasedUsers", addPurchasedUsersParams).then(() => {
                this.getBillingInfo()
                this.cancelAddPurchasedUsers()
                this.addDeleteUserConfirmActive = false
                this.$vs.notify({
                    title:'Success',
                    text: 'Your additional users have been updated successfully.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
                this.$vs.loading.close()
            }).catch(e => {
                this.showError(e);
                this.$vs.notify({
                    title:'Error',
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: 'feather',
                    icon:'icon-alert-circle',
                    position:"top-right",
                    time:4000
                });
            });    
        },
        getYears(){
            let cyArray = [];
            let startYear = new Date().getFullYear()
            let endYear = new Date().getFullYear() + 10
            for (let index = startYear; index < endYear; index++) {
                cyArray.push(index);
            }
            this.billingCardExpYearOptions = cyArray;
        },
    }
};
</script>
